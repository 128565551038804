import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { ConfigService } from "src/app/services/config.service";
import { environment } from "src/environments/environment";

@Component({
    templateUrl: "./receipt.component.html",
    styleUrls: ["./receipt.component.scss"],
    selector: "app-receipt"
})
export class ReceiptComponent {
    routeName: string;
    reciptId: any;
    purchase = null;

    error: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
        private configService: ConfigService) {
        moment.locale("sv-se");
    }

    async ngOnInit() {
        let params = this.activatedRoute.snapshot.params;
        this.routeName = params.route;
        this.reciptId = params.id;

        await this.configService.setClientTheme(this.routeName);
        this.configService.init(this.routeName);

        let url = `${environment.pubQueueApiUrl}/api/v1/receipt/data?u=${this.routeName}&r=${this.reciptId}`;
        this.purchase = await this.http.get<any>(url).toPromise();

        if (!this.purchase)
            this.error = true;
    }
    getBookingString(receipt) {
        let theMoment = moment.unix(receipt.digitalOrder.data.booking.timeslotUTC).locale("sv-se");
        return receipt.digitalOrder && receipt.digitalOrder.data.booking ? "Bokning för " + theMoment.format('dddd, D MMMM YYYY') + " kl" + theMoment.format(`HH:mm`) : '';
    }
    getDate(timestamp) {
        moment.locale("sv-se");
        moment.unix(timestamp).format("YYYY-MM-DD HH:mm")

        return moment.unix(timestamp).format("YYYY-MM-DD HH:mm")
    }

    getSerialNo(extraData) {
        return JSON.parse(extraData).controlUnit.serialNo
    }
}
