import { Component, ViewChild, Input } from "@angular/core";
import * as Reepay from 'reepay';
import {
  ModalController,
  AlertController,
  LoadingController,
  ToastController,
} from "@ionic/angular";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "add-card",
  styleUrls: ["addCard.component.scss"],
  templateUrl: "addCard.component.html",
})
export class AddCardComponent {
  @Input() sessionId: string = "";
  canNotDismiss = false;
  showForm: boolean = false;
  currency = "SEK";
  userId = "";
  routeName = "";  
  baseUrl = `https://us-central1-pq2-staging.cloudfunctions.net/payments/v1`;
  rp = null;
  constructor(
    private modalController: ModalController,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private http: HttpClient,

  ) {

  }

  ngAfterViewInit() {
    //something steals focus after viewinit so timeout here
    setTimeout(() => {
      // load reepay components
      this.showReepayEmbeddedComponent(this.sessionId);

    }, 700);
  }

  async handleSubmission(state, component, url) {
    try {
      const res = await this.callServer(url, state.data);
      this.handleServerResponse(res, component);
    } catch (error) {
      console.error(error);
    }
  }

  async callServer(url, data) {
    const res = await this.http.post(url, data ? data : "", {
      headers: {
        "Content-Type": "application/json"
      }
    }).toPromise();

    return await res;
  }

  handleServerResponse(res, component) {
    if (res.action) {
      component.handleAction(res.action);
    } else {
      alert(JSON.stringify(res));
      // switch (res.resultCode) {
      //   case "Authorised":
      //     window.location.href = "/result/success";
      //     break;
      //   case "Pending":
      //   case "Received":
      //     window.location.href = "/result/pending";
      //     break;
      //   case "Refused":
      //     window.location.href = "/result/failed";
      //     break;
      //   default:
      //     window.location.href = "/result/error";
      //     break;
      // }
    }
  }

  select(event) {
    event.target.children[0].select();
  }

  dismiss() {
    if (this.rp)
      this.destroyReepayEmbeddedComponent();

    this.modalController.dismiss();
  }


  /************************ Reepay *************************** */
  ///
  /// Show Reepay Embedded component 
  ///
  showReepayEmbeddedComponent(sessionId: string) {
    try {
      this.rp = new Reepay.EmbeddedCheckout(sessionId, { html_element: 'rp_container' });
      // Add component events
      this.rp.addEventHandler(Reepay.Event.Accept, (data) => this.HandleReepaySuccessEvent(data, this));
      this.rp.addEventHandler(Reepay.Event.Error, (data) => this.HandleReepayFailureEvent(data, this));
      this.rp.addEventHandler(Reepay.Event.Close, (data) => this.HandleReepayCloseEvent(data, this));
      //alert("Rendered reepay");
    } catch (error) {
      alert(JSON.stringify(error));
    }

  }

  destroyReepayEmbeddedComponent() {
    this.rp.removeEventHandler(Reepay.Event.Accept);
    this.rp.removeEventHandler(Reepay.Event.Error);
    this.rp.removeEventHandler(Reepay.Event.Close);
    this.rp.destroy();
  }

  // Accept: fires once the transaction is successfully completed.
  HandleReepaySuccessEvent(data: any, context: AddCardComponent) {
    // Remove possibility to dismiss modal, wait for automatic closing
    this.canNotDismiss = true;

    // Wait 2 sek befor closeing to show success message
    setTimeout(() => {
      this.destroyReepayEmbeddedComponent();
      this.modalController.dismiss(data, "confirm");
    }, 2000);
  }

  // Error: whenever an error has occurred in attempting to finalise the transaction.
  HandleReepayFailureEvent(data: any, context: AddCardComponent) {
    console.log('Reepay Component -> Failure');
    console.dir(data);
  }

  // Close: fires once the embedded window is closed. This event is independent of the 
  // previous, as none of the above events automatically close the window  
  // - it is only ever done by calling the destroy function seen below.
  HandleReepayCloseEvent(data: any, context: AddCardComponent) {
    console.log('Reepay Component - Close');
    console.dir(data);
  }
}