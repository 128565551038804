import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";

import { AlertController, IonContent, LoadingController, ModalController, NavController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { BookingStateService, Checkin, Guest } from "../services/bookingState.service";
import { CardPaymentProvider } from "../services/cardPaymentProvider";
import { Order, PaymentV2Response } from "../services/paymentUtils";
import { AddCardComponent } from "./add-card/addCard.component";
import confetti from 'canvas-confetti';
import { AngularFireDatabase } from "@angular/fire/database";
import * as moment from "moment";
import { SwishDialogComponent } from "./swish-dialog/swish-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'app-payment',
    templateUrl: './payment.page.html',
    styleUrls: ['./payment.page.scss'],
})
export class PaymentPageComponent implements OnInit {
    @ViewChild(IonContent, { static: false }) content: IonContent
    acceptedTurms: boolean = false;
    acceptedNewsletter: boolean = false;
    preorderItems: any[] = [];
    paymentOptions = [
        { name: "Kort", type: "card", imgUrl: "./assets/cards.png", isSelected: false },
        { name: "Swish", type: "swish", imgUrl: "./assets/swish.png", isSelected: false }
    ];
    seatsToPay = [
        { id: "all", name: "Alla", imgUrl: "people", isSelected: false, disabled: false },
        { id: "one", name: "Mig", imgUrl: "person", isSelected: false, disabled: false }
    ]

    checkinId = this.bookingStateService.createCheckinId();
    guestId = this.bookingStateService.createGuestId();
    bookingId = this.bookingStateService.createBookingId();

    bookingItem;
    mustPayFullAmount = false

    couponCode: string;
    closingTime: string;
    couponCodeIconStyle = {
        icon: 'barcode',
        color: 'grey'
    };
    couponCodeEnabled = true;
    validCoupon = null;

    constructor(
        public bookingStateService: BookingStateService,
        private navController: NavController,
        private loadingController: LoadingController,
        private modalController: ModalController,
        private alertController: AlertController,
        private cardPaymentProvider: CardPaymentProvider,
        private http: HttpClient,
        private afDatabase: AngularFireDatabase,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {

    }

    async ngOnInit() {
        let possibleParams = this.activatedRoute.snapshot.params;
        const routeName = possibleParams.routeName;
        this.bookingStateService.init(routeName);
        this.preorderItems = await this.bookingStateService.getItems(routeName);
        this.bookingItem = await this.bookingStateService.getBookingItem(routeName);
        const bookingIdFromPOS = possibleParams.bookingId;
        if (bookingIdFromPOS) {
            this.bookingStateService.booking = await this.bookingStateService.getBooking(routeName, bookingIdFromPOS);
            this.bookingStateService.booking.seats = parseInt(this.bookingStateService.booking["peopleCount"]);
            this.bookingStateService.booking.firstName = this.bookingStateService.booking["userFirstName"];
            this.bookingStateService.booking.lastName = this.bookingStateService.booking["userLastName"];
            this.bookingStateService.booking.nameArtist = this.bookingStateService.booking.firstName;
            this.bookingStateService.bookingItem.NrOfItems = this.bookingStateService.booking.seats;
            this.bookingStateService.booking.unitRouteName = routeName;
            this.bookingStateService.booking.allSeatsArePayed = false;
            this.bookingStateService.booking.timeslotUTC = this.bookingStateService.booking.startTimeUTC;
            this.bookingStateService.booking.bookerIncluded = false;
            this.bookingStateService.booking.preorderedItems = [];
            this.bookingStateService.booking.originalBookingIdFromPOS = bookingIdFromPOS;

        }

        this.bookingId = this.bookingStateService.createBookingId();
        this.checkinId = this.bookingStateService.createCheckinId();
        this.guestId = this.bookingStateService.createGuestId();


        const now = moment(new Date()); //todays date
        const time = moment.unix(this.bookingStateService.booking.timeslotUTC as number).local(); // another date
        var duration = moment.duration(time.diff(now));
        this.mustPayFullAmount = duration.asHours() < 168;
        if (bookingIdFromPOS) {
            this.seatsToPay = []
            this.seatsToPay.push({ id: "all", name: "Alla", imgUrl: "people", isSelected: false, disabled: false });
            this.seatsToPay.push({ id: "one", name: "Mig", imgUrl: "person", isSelected: false, disabled: this.mustPayFullAmount })
        }
        else if (!this.bookingStateService.booking.bookerIncluded) {
            this.seatsToPay = []
            this.seatsToPay.push({ id: "all", name: "Alla", imgUrl: "people", isSelected: false, disabled: false })
            this.seatsToPay.push({ id: "none", name: "Ingen", imgUrl: "", isSelected: false, disabled: this.mustPayFullAmount })
        } else {
            this.seatsToPay = []
            this.seatsToPay.push({ id: "all", name: "Alla", imgUrl: "people", isSelected: false, disabled: false })
            this.seatsToPay.push({ id: "one", name: "Mig", imgUrl: "person", isSelected: false, disabled: this.mustPayFullAmount })
        }

        this.bookingStateService.booking.bookingPriceItem = this.bookingItem;
        this.bookingStateService.booking.bookingPriceItem.NrOfItems = this.bookingStateService.booking.seats;
        this.selectSeatsToPay(this.seatsToPay[0]);
        this.selectPaymentOption(this.paymentOptions[0]);
    }
    back() {
        this.navController.navigateBack("book", {
            queryParamsHandling: "preserve"
        });
    }
    getPaymentButtonText() {

        if (!this.bookingStateService.booking.bookerIncluded && this.bookingStateService.booking.bookingPriceItem && this.bookingStateService.booking.bookingPriceItem.NrOfItems < 1)
            return 'Boka'
        else {
            if (!this.validCoupon) {
                return `Betala ${this.getTotalPrice() / 100} kr`;
            } else {
                return `Betala ${((this.getTotalPrice() - this.getDiscountAmount()) / 100)} kr`;
            }
        }
    }
    selectItem(item, operation: "add" | "remove") {
        let existingItem = this.bookingStateService.booking.preorderedItems.find(x => x.key === item.key);
        if (operation === "add") {
            if (existingItem)
                existingItem.NrOfItems += 1;
            else
                this.bookingStateService.booking.preorderedItems.push({ NrOfItems: 1, ...item });
        }
        if (operation === "remove") {
            if (existingItem) {
                if (existingItem.NrOfItems === 1) {
                    this.bookingStateService.booking.preorderedItems.splice(this.bookingStateService.booking.preorderedItems.findIndex(x => x.key === item.key), 1);
                }
                else {
                    existingItem.NrOfItems -= 1;
                }
            }

        }
    }
    getPreorderItemQuantity(item) {
        let existingItem = this.bookingStateService.booking.preorderedItems.find(x => x.key === item.key);
        return existingItem ? existingItem.NrOfItems : 0;
    }
    getTotalPrice() {
        if (!this.bookingStateService.booking || !this.bookingStateService.booking.bookingPriceItem)
            return 0;
        let preorderedItemsPrice = 0;
        this.bookingStateService.booking.preorderedItems.forEach(x => {
            preorderedItemsPrice += (x.Cost * x.NrOfItems);
        });
        let bookingPrice = this.bookingStateService.booking.bookingPriceItem.NrOfItems * this.bookingStateService.booking.bookingPriceItem.Cost;
        return preorderedItemsPrice + bookingPrice;
    }
    getDiscountAmount() {
        if (!this.bookingStateService.booking || !this.bookingStateService.booking.bookingPriceItem)
            return 0;
        let bookingPrice = this.bookingStateService.booking.bookingPriceItem.NrOfItems * this.bookingStateService.booking.bookingPriceItem.Cost;
        return (bookingPrice * (this.validCoupon ? this.validCoupon.discountPercent / 100 : 0));
    }
    selectPaymentOption(item) {
        this.paymentOptions.forEach(x => {
            x.isSelected = false;
        });
        item.isSelected = true;
        this.bookingStateService.booking.paymentOption = item;
        this.content.scrollToBottom();

    }
    selectSeatsToPay(item) {
        if (!item.disabled) {
            this.seatsToPay.forEach(x => {
                x.isSelected = false;
            });
            item.isSelected = true;
            if (item.id === "one") {
                this.bookingStateService.booking.bookingPriceItem.NrOfItems = 1;
                this.bookingStateService.booking.payForAll = false;
                this.bookingStateService.booking.bookerIncluded = true;
            }
            else if (item.id === "none") {
                this.bookingStateService.booking.bookingPriceItem.NrOfItems = 0;
                this.bookingStateService.booking.payForAll = false;
            } else {
                this.bookingStateService.booking.bookingPriceItem.NrOfItems = this.bookingStateService.booking.seats;
                this.bookingStateService.booking.payForAll = true;
            }
        } else {
            this.showOkAlert("Vid bokning närmare än 48 timmar behöver vi få in betalning för samtliga vid bokning.");
        }
    }

    paymentOptionSelected() {
        return this.bookingStateService.booking.paymentOption && this.acceptedTurms;
    }
    async createSession() {
        let loading = await this.loadingController.create({
            spinner: "crescent"
        });
        loading.present();
        if (!this.bookingStateService.booking.originalBookingIdFromPOS) {
            let bookingMomentToVerify = moment.unix(<any>this.bookingStateService.booking.timeslotUTC);
            if (bookingMomentToVerify.hour() > 0 && bookingMomentToVerify.hour() < 5)
                bookingMomentToVerify.subtract(1, "day").set("hour", 10);
            let url = `${environment.primeApiUrl}/getavailabletimeslots`;
            let data = {
                unitRouteName: this.bookingStateService.booking.unitRouteName,
                seats: this.bookingStateService.booking.seats,
                dateUTC: bookingMomentToVerify.unix()
            };
            let timeslots = await this.http.post<{ data: any[] }>(url, data).toPromise();
            let exists = timeslots.data.find(x => x === this.bookingStateService.booking.timeslotUTC);
            if (!exists) {
                loading.dismiss();
                let myAlert = await this.alertController.create({ message: "Tyvärr! Någon annan har hunnit boka denna tid. Välj en ny tid från startsidan", buttons: [{ text: "OK" }], mode: "ios" });
                myAlert.present();
                await myAlert.onDidDismiss();
                this.router.navigateByUrl(`?r=${this.bookingStateService.booking.unitRouteName}`);
                return;
            }
        }
        let order = this.createOrder(this.bookingStateService.booking.email);

        try {
            // Nothing to pay
            // Save booking and navigate forward
            if (!this.bookingStateService.booking.bookerIncluded &&
                this.bookingStateService.bookingItem &&
                this.bookingStateService.bookingItem.NrOfItems < 1) {

                this.bookingStateService.booking.id = this.bookingId;

                let data = this.bookingStateService.booking;
                let url = `${environment.primeApiUrl}/booktimeslot`;
                let ms = 500;
                for (let index = 0; index < 10; index++) {
                    try {
                        let bookResult = await this.http.post<any>(url, data).toPromise();
                        this.navController.navigateForward(`booking/${this.bookingStateService.booking.unitRouteName}/${bookResult.data.bookingId}?congrats=true`)
                        break;
                    } catch (error) {
                        await this.wait(ms);
                        ms += 200
                    }

                }
                loading.dismiss();

                return;
            }
            // Update booking on order
            this.bookingStateService.booking.id = this.bookingId;
            order.booking = this.bookingStateService.booking;
            order.booking.isNew = true;
            order.booking.id = this.bookingId;
            // Add booker to the guests if included
            if (this.bookingStateService.booking.bookerIncluded) {
                let guest: Guest = {
                    fullname: this.bookingStateService.booking.firstName + " " + this.bookingStateService.booking.lastName,
                    name: this.bookingStateService.booking.nameArtist,
                    email: this.bookingStateService.booking.email,
                    phone: this.bookingStateService.booking.phoneNumber,
                    paid: true,
                    acceptanceOfConditions: this.acceptedTurms,
                    acceptanceOfNewsletter: this.acceptedNewsletter,
                    child: false,
                    parentIndex: -1,
                    key: this.guestId
                }
                order.booking.guestsToAdd = [guest];
            }
            // Init payment
            let response: PaymentV2Response = null;

            // FREECHARGE
            if (this.getTotalPrice() - this.getDiscountAmount() <= 0) {
                response = await this.bookingStateService.createFreechargeSession({
                    order
                });
                loading.dismiss();

                if (response.error || !response.providerData || !response.providerData.freecharge) {
                    if (response.error && response.error.responseText)
                        this.showOkAlert(response.error.responseText);

                    this.bookingStateService.updateIdempotencyKey();
                    return
                }

                // SWISH
            } else if (this.bookingStateService.booking.paymentOption.type === "swish") {
                loading.dismiss();
                let swishPay = await this.modalController.create({
                    component: SwishDialogComponent,
                    componentProps: { order, booking: this.bookingStateService.booking, phone: this.bookingStateService.booking.phonePrefix + this.bookingStateService.booking.phoneNumber },
                    backdropDismiss: false
                });
                swishPay.present();
                let result = await swishPay.onDidDismiss();
                if (!result.data || result.role != "success") return
                console.dir(result)
                response = result.data;
                // CARD
            } else {
                response = await this.bookingStateService.createChargeSession({
                    order
                });
                loading.dismiss();

                if (response.error || !response.providerData || !response.providerData.id) {
                    if (response.error && response.error.responseText)
                        this.showOkAlert(response.error.responseText);

                    this.bookingStateService.updateIdempotencyKey();
                    return
                }

                var sessionId = response.providerData.id;
                let cardPay = await this.modalController.create({
                    component: AddCardComponent,
                    componentProps: { sessionId },
                    backdropDismiss: false
                });
                cardPay.present();
                let result = await cardPay.onDidDismiss();
                if (!result.data) {
                    // User closed dialog
                    this.bookingStateService.updateIdempotencyKey();
                    return
                }
                console.dir(result)
            }

            // Will verify payment for card payments and freecharge
            await this.verifyPayment(loading, this.bookingId, response);

        } catch (error) {
            console.error(error.message);
            loading.dismiss();
        }
    }

    async verifyPayment(loading, bookingId, initResponse) {
        // Verifying payment
        loading = await this.loadingController.create({
            spinner: "crescent",
            message: "Bekräftar..."
        });

        loading.present();
        let pollingResult = await this.cardPaymentProvider.verify(initResponse.idempotencyKey, this.bookingStateService.booking.unitRouteName);
        if (pollingResult.success) {
            this.navController.navigateForward(`booking/${this.bookingStateService.booking.unitRouteName}/${bookingId}?congrats=true`)
            loading.dismiss();
        }
        else {
            if (pollingResult.error && pollingResult.error.responseText) {
                this.bookingStateService.updateIdempotencyKey();
                this.showOkAlert(pollingResult.error.responseText);
            }
            loading.dismiss();
        }
    }

    getReceiptAddress() {
        return 'Ditt kvitto kommer skickas via mail'
    }

    createOrder(email) {
        let orderId = this.bookingStateService.updateOrderId();
        this.bookingStateService.updateIdempotencyKey();
        let order = new Order();
        order.order = { food: [this.bookingStateService.booking.bookingPriceItem], drinks: [] };
        this.bookingStateService.booking.preorderedItems && this.bookingStateService.booking.preorderedItems.forEach(x => {
            order.order.food.push(x);
        })
        order.client = { routeName: this.bookingStateService.booking.unitRouteName, address: "", name: "Bokning", phoneNumber: "", location: null };
        order.id = orderId;
        order.totalCost = this.getTotalPrice();
        order.booking = this.bookingStateService.booking;
        order.userId = "tgNRpho4tGgMus1yihfjeIDP1Ud2";
        if (this.validCoupon) {
            order.coupon = this.validCoupon;
            order.totalCost -= this.getDiscountAmount();
        }
        if (email) order.email = email
        return order;
    }

    canPay() {

        let ok: boolean = false

        if (
            !this.bookingStateService.booking.bookerIncluded &&
            this.bookingStateService.booking.bookingPriceItem &&
            this.bookingStateService.bookingItem.NrOfItems < 1
        ) {
            ok = (this.bookingStateService.booking.bookingPriceItem.NrOfItems === 0 &&
                this.acceptedTurms
            )
        } else {
            ok = (this.paymentOptionSelected() && this.acceptedTurms)
        }

        return ok
    }
    wait(ms) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(null);
            }, ms);
        });
    }


    private async showOkAlert(text: string) {
        const alert = await this.alertController.create({
            header: text,
            buttons: [
                {
                    text: 'Ok',
                    handler: () => {
                    }
                }
            ]
        });
        alert.present();
    }

    async validateCouponCode() {

        this.couponCode = this.couponCode.toUpperCase();
        const clientToValidateCouponAgainst = this.bookingStateService.booking.unitRouteName;
        const loader = await this.loadingController.create({
            message:
                'Lägger på rabattkod', spinner: 'circular'
        });
        loader.present();

        const body = {
            couponCode: this.couponCode,
            client: clientToValidateCouponAgainst
        }
        this.http.post(environment.pubQueueApiUrl + "/checkcoupon", body).subscribe(data => {
            //this.paymentProvider.validCoupon = data;
            loader.dismiss();
            this.validCoupon = data;
            confetti(
                {
                    particleCount: 65,
                    angle: 120,
                    spread: 55,
                    origin: { x: 1, y: 0.7 }
                }
            );

            setTimeout(() => {
                confetti.reset();
            }, 1500);
            if (this.validCoupon.discountPercent === "100" || this.validCoupon.discountPercent === 100)
                this.selectPaymentOption(this.paymentOptions[0]);
        }, error => {
            loader.dismiss();
            this.showOkAlert('Rabattkoden är inte giltig');
        });
    }

    showCouponError() {
        this.validCoupon = null;
        this.couponCodeIconStyle.icon = 'alert';
        this.couponCodeIconStyle.color = 'red';
    }

    resetCouponIconOnEmpty() {
        if (this.validCoupon == null && this.couponCode.length === 0) {
            this.couponCodeIconStyle.icon = 'barcode';
            this.couponCodeIconStyle.color = 'grey';
        }
    }

    async removeCoupon() {

        let alert = await this.alertController.create({
            message: "Ta bort koden?",
            buttons: [{
                text: "Avbryt",
                role: "cancel"
            },
            {
                text: "Ok",
                role: "confirm"
            }
            ]
        });
        alert.present();
        let result = await alert.onDidDismiss();
        if (result.role === "confirm") {
            this.validCoupon = null;
            this.couponCode = "";
            this.couponCodeIconStyle.icon = "barcode";
            this.couponCodeIconStyle.color = "grey";
        }
    }

}