import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BookCompletedComponent } from './book-completed/book-completed.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { PaymentPageComponent } from './payment/payment.page';
import { AddCardComponent } from './payment/add-card/addCard.component';
import { AddGuestComponent } from './book-completed/add-guest/add-guest.component';
import { ClosedComponent } from './closed/closed.component';
import { SwishDialogComponent } from './payment/swish-dialog/swish-dialog.component';
import { CheckinCompletedComponent } from './book-completed/checkin-completed/checkin-completed.component'
import { ClipboardModule } from 'ngx-clipboard';
import { ReceiptComponent } from './book-completed/receipt-completed/receipt.component';
import { AcceptanceOfConditionsComponent } from './book-completed/acceptance-of-conditions/acceptance-of-conditions.component';
import { BookingDisplayComponent } from './bookingDisplay/bookingDisplay.component';

@NgModule({
  declarations: [AppComponent, BookCompletedComponent, PaymentPageComponent, CheckinCompletedComponent,
    AddCardComponent, AddGuestComponent, AcceptanceOfConditionsComponent, ClosedComponent, SwishDialogComponent, ReceiptComponent, BookingDisplayComponent],
  entryComponents: [AddCardComponent, AddGuestComponent, ClosedComponent, SwishDialogComponent, CheckinCompletedComponent, ReceiptComponent],
  imports: [
    BrowserModule,
    ClipboardModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule.forRoot({
      mode: "ios"
    }),
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.database),
    AngularFireDatabaseModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
