import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { PaymentUtils, PaymentV2Response } from './paymentUtils';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: "root"
})
export class CardPaymentProvider {
    appBuild = "";
    sub: Subscription;

    constructor(
        private http: HttpClient,
        private paymentUtils: PaymentUtils
    ) {
        // this.platform.ready().then(async x => {
        //     this.appBuild = `${await this.appVersion.getVersionNumber()}_${await this.appVersion.getVersionCode()}`
        // })        
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
    }

    async initiate(options: { paymentSource: any, order: any, clientContextData: any }, idempotencyKey: string, orderId: string): Promise<PaymentV2Response> {
        let receiptOnly = false;
        try {
            receiptOnly = true
        } catch (error) {
            receiptOnly = false;
        }

        options.order.id = orderId;

        const providerData = {
            type: options.paymentSource.type,
            paymentSource: options.paymentSource,
            provider: "reepay",
            customer: null
        }
        const body = {
            idempotencyKey: idempotencyKey,
            order: options.order,
            paymentType: options.paymentSource.type,
            userPaymentSource: options.paymentSource,
            clientContext: options.clientContextData,
            receiptOnly,
            providerData
        };

        return this.paymentUtils.initiatePaymentWithRetry(body, this.http);
    }

    async charge(idempotencyKey: string, routeName: string): Promise<PaymentV2Response> {

        const body = {
            idempotencyKey: idempotencyKey,
            routeName: routeName,
        };

        let url = `${environment.pubQueueApiUrl}/payments/v1/idempotency/charge`;
        let resp = await this.http.post<PaymentV2Response>(url, body).toPromise();
        return resp;
    }

    async verify(idempotencyKey: string, routeName: string): Promise<PaymentV2Response> {

        return this.paymentUtils.verifyPaymentWithRetry(idempotencyKey, routeName, this.http, 45);
    }
}
