import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment'
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { IonContent, NavController } from '@ionic/angular';
import { Booking, BookingStateService } from '../services/bookingState.service';

@Component({
  selector: 'app-book-slot',
  templateUrl: './book-slot.component.html',
  styleUrls: ['./book-slot.component.scss'],
})
export class BookSlotComponent implements OnInit {
  routeName: string;
  loading = false;
  timeSlot: moment.Moment;
  seats = 0;
  title = "";
  subTitle = "";
  name = "";
  phoneNumber = "";
  phoneNumberPrefix = "+46";
  comment = "";
  email = "";
  prepay = true;
  nameArtist = "";

  bookerIncluded = true;

  hideComment: boolean = true // TODO: Need to be added to the booking settings
  @ViewChild(IonContent, { static: false }) content: IonContent
  @ViewChild('nameField', { static: false }) nameField;
  @ViewChild('phonePrefixField', { static: false }) phonePrefixField;
  @ViewChild('phoneField', { static: false }) phoneField;
  @ViewChild('emailField', { static: false }) emailField;
  @ViewChild('commentField', { static: false }) commentField;
  @ViewChild('submitBtn', { static: false }) submitBtn;
  constructor(private activatedRoute: ActivatedRoute, private http: HttpClient, private router: Router, private navCtrl: NavController, private bookingStateService: BookingStateService) {
    moment.locale("sv-se");

    this.activatedRoute.queryParams.subscribe(x => {
      this.timeSlot = <moment.Moment>moment(JSON.parse(x.timeSlot).timeMoment);
      this.seats = x.seats;
      this.routeName = x.routeName;
      this.title = this.timeSlot.local().format('dddd, D MMMM YYYY').toUpperCase();
      this.subTitle = this.timeSlot.local().format("HH:mm");
      if (parseInt(this.subTitle) === 24)
        this.subTitle = this.subTitle.replace("24", "00");
    });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.name)
        this.phoneField.setFocus();
      else
        this.nameField.setFocus();
    }, 700);
  }
  ngOnInit() {
    this.phoneNumberPrefix = '+46'
  }
  back() {
    this.navCtrl.navigateBack("", { queryParams: { r: this.routeName } });
  }
  formValid() {

    let avatarOk = true

    if (this.bookerIncluded)
      avatarOk = this.nameArtist && this.nameArtist !== ''

    return this.validatePhone(this.phoneNumber) && this.phoneNumberPrefix && this.name
      && this.validateEmail(this.email);
  }
  changes() {
    setTimeout(() => {
      this.content.scrollToBottom();
    }, 300)
  }
  select(event) {
    event.target.children[0].select();
  }
  selectNextField($event: KeyboardEvent, fieldName) {
    if ($event.keyCode !== 13 || $event.key !== "Enter")
      return;
    if (this[fieldName].setFocus) {
      this[fieldName].setFocus();
      return;
    }
    // this[fieldName]._elementRef.nativeElement.select();
    document.getElementById("submitBtn").focus();
  }

  async bookTimeSlot() {
    if (!this.formValid())
      return;

    let firstName = this.name.split(" ")[0];
    let lastName = this.name.split(" ")[1] ? this.name.split(" ")[1] : " ";

    if (!this.phoneNumber.startsWith('+')) {
      if (this.phoneNumber.startsWith('0')) {
        this.phoneNumber = this.phoneNumber.substring(1, this.phoneNumber.length)
        this.phoneNumber = this.phoneNumber.padStart(this.phoneNumber.length + 3, '+46')
      }
    }

    this.bookingStateService.booking = new Booking();
    this.bookingStateService.booking.bookerIncluded = this.bookerIncluded;
    this.bookingStateService.booking.comment = this.comment;
    this.bookingStateService.booking.email = this.email;
    this.bookingStateService.booking.firstName = firstName;
    this.bookingStateService.booking.lastName = lastName;
    this.bookingStateService.booking.phoneNumber = this.phoneNumber;
    this.bookingStateService.booking.phonePrefix = ''//this.phoneNumberPrefix; prefix is added in the phoneNumber
    this.bookingStateService.booking.seats = this.seats;
    this.bookingStateService.booking.timeslotUTC = this.timeSlot.unix();
    this.bookingStateService.booking.unitRouteName = this.routeName;
    this.bookingStateService.booking.nameArtist = this.nameArtist !== '' ? this.nameArtist : this.name;
    // this.loading = true;

    this.router.navigateByUrl(`pay/${this.routeName}`);

  }

  validateEmail(email) {
    let result = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    return result;
  }

  validatePhone(phone) {
    let result = String(phone)
      .toLowerCase()
      .match(
        /^(\+\d{2})\d{7,15}$/
      );
    if (!result) result = String(phone)
      .toLowerCase()
      .match(
        /^\d{8,15}$/
      );
    return result;
  }
}
