import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NavController, LoadingController, AlertController, ModalController, IonContent } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import * as moment from 'moment'
import { AngularFireDatabase } from "@angular/fire/database";
import { BookingStateService } from "../services/bookingState.service";
import { ConfigService } from "../services/config.service";
import { Order } from "../services/paymentUtils";
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard'
import { ToastController } from '@ionic/angular';
import { Subscription } from "rxjs";
@Component({
    templateUrl: "./book-completed.component.html",
    styleUrls: ["./book-completed.component.scss"],
    selector: "app-book-completed"
})
export class BookCompletedComponent {
    @ViewChild(IonContent, { static: false }) content: IonContent;
    object = Object;
    id: string;
    routeName: string;
    activeTableBooking: any;
    loadingBooking = true;
    showCongratulationsMessage = false;
    confirmedCongratulation = false;
    restaurantInfo;
    showRegisterForm = false;
    link: string = '';
    preorderItems;
    subscriptions = new Subscription();
    constructor(
        private _clipboardService: ClipboardService,
        private afDatabase: AngularFireDatabase,
        private bookingStateService: BookingStateService,
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
        private navCtrl: NavController,
        private loadingController: LoadingController,
        private alertController: AlertController,
        private configService: ConfigService,
        public toastController: ToastController
    ) {
        moment.locale("sv-se");
    }
    async ngOnInit() {
        let queryParams = this.activatedRoute.snapshot.queryParams
        this.showCongratulationsMessage = queryParams.congrats;
        this.confirmedCongratulation = !this.showCongratulationsMessage;

        this.subscriptions.add(
            this._clipboardService.copyResponse$.subscribe(async (res: IClipboardResponse) => {
                if (res.isSuccess) {
                    let toast = await this.toastController.create({
                        message: 'Informationen har kopierats',
                        duration: 2000,
                        color: 'primary',

                    });

                    toast.present();
                } else {
                    alert("Kunde inte kopiera informationen, prova igen.");
                }
            })
        )
        let params = this.activatedRoute.snapshot.params;
        this.id = params.id;
        this.routeName = params.route;

        this.link = window.location.hostname.startsWith('localhost') ? `http://${window.location.hostname}:8100/booking/${this.routeName}/${this.id}` : `https://${window.location.hostname}/booking/${this.routeName}/${this.id}`

        await this.configService.setClientTheme(this.routeName);
        this.bookingStateService.init(this.routeName);
        let url = `${environment.pubQueueApiUrl}/getbooking?r=${this.routeName}&b=${this.id}`;
        let retries = 8;
        let delayMs = 1000;
        let restaurantPromise = this.afDatabase.database.ref(`adminApp/clients/${this.routeName}/info/public`).once("value");
        for (let index = 0; index < retries; index++) {
            await this.wait(delayMs);
            let bookingPromise = this.http.post<any>(url, null).toPromise();
            this.activeTableBooking = await bookingPromise;
            if (!this.activeTableBooking)
                delayMs = delayMs * 1.4;
            else
                break;
        }
        this.activeTableBooking.id = this.id;
        this.restaurantInfo = (await restaurantPromise).val();
        this.bookingStateService.booking = this.activeTableBooking;
        let startTimeMoment = moment.unix(this.activeTableBooking.startTimeUTC).local();
        let endTimeMoment = moment.unix(this.activeTableBooking.endTimeUTC).local();
        this.activeTableBooking.dateTimePretty = startTimeMoment.format('dddd, D MMMM YYYY').toUpperCase();
        this.activeTableBooking.startTimePretty = startTimeMoment.format("HH:mm");
        this.activeTableBooking.endTimePretty = endTimeMoment.format("HH:mm");
        this.preorderItems = this.activeTableBooking.preorderedItems;
        this.loadingBooking = false;
    }

    ngDestroy() {
        this.subscriptions.unsubscribe();
    }
    confirmInfo() {
        this.confirmedCongratulation = true;
    }

    getCheckinTime(time) {
        return moment.unix(time).local().format("YYYY-MM-DD")
    }

    getNrOfGuests() {
        const guests = this.activeTableBooking.peopleCount;
        let count = guests;
        let nrOfCommitedGuests = this.activeTableBooking.guests ? Object.keys(this.activeTableBooking.guests).length : 0;

        return count - nrOfCommitedGuests
    }
    selectItem(item, operation: "add" | "remove") {
        let existingItem = this.bookingStateService.booking.preorderedItems.find(x => x.key === item.key);
        if (operation === "add") {
            if (existingItem)
                existingItem.NrOfItems += 1;
            else
                this.bookingStateService.booking.preorderedItems.push({ NrOfItems: 1, ...item });
        }
        if (operation === "remove") {
            if (existingItem) {
                if (existingItem.NrOfItems === 1) {
                    this.bookingStateService.booking.preorderedItems.splice(this.bookingStateService.booking.preorderedItems.findIndex(x => x.key === item.key), 1);
                }
                else {
                    existingItem.NrOfItems -= 1;
                }
            }

        }
    }
    openLink(link: string) {
        window.open(link, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }

    async cancelBooking() {
        let alert = await this.alertController.create({
            header: "Är du säker på att du vill avboka?",
            buttons: [{ text: "Nej", role: "cancel" }, { role: "confirm", text: "Ja" }]
        });
        alert.present();
        let result = await alert.onDidDismiss();
        if (result.role !== "confirm")
            return;
        let loading = await this.loadingController.create({
            spinner: "crescent",
            message: "Avbokar",
            duration: 6000
        });
        loading.present();
        let data = {
            unitRouteName: this.routeName,
            bookingId: this.id
        };
        let url = `${environment.primeApiUrl}/canceltimeslot`;
        this.http.post<any>(url, data).subscribe(x => {
            this.navCtrl.navigateBack("/");
            loading.dismiss();
        });
    }
    async registerGuest() {

        this.showRegisterForm = !this.showRegisterForm;
    }
    async onGuestsRegistered(event: { guests: { name: string, email: string, phone: string, paid: boolean, child: boolean, parentIndex: number }[] }) {
        let loading = await this.loadingController.create({
            spinner: "crescent"
        });
        loading.present();

        this.navCtrl.navigateForward(`checkin/completed/${this.routeName}/${this.id}`)
        let url = `${environment.pubQueueApiUrl}/getbooking?r=${this.routeName}&b=${this.id}`;

        let retries = 5;
        let delayMs = 1000;
        for (let index = 0; index < retries; index++) {
            await this.wait(delayMs);
            let bookingPromise = this.http.post<any>(url, null).toPromise();
            this.activeTableBooking = await bookingPromise;
            if (!this.activeTableBooking)
                delayMs = delayMs * 1.4;
            else
                break;
        }
        this.activeTableBooking.id = this.id;

        this.bookingStateService.booking = this.activeTableBooking;
        let startTimeMoment = moment.unix(this.activeTableBooking.startTimeUTC).local();
        let endTimeMoment = moment.unix(this.activeTableBooking.endTimeUTC).local();
        this.activeTableBooking.dateTimePretty = startTimeMoment.format('dddd, D MMMM YYYY').toUpperCase();
        this.activeTableBooking.startTimePretty = startTimeMoment.format("HH:mm");
        this.activeTableBooking.endTimePretty = endTimeMoment.format("HH:mm");

        this.showRegisterForm = false;
        loading.dismiss();
    }

    wait(ms) {
        return new Promise((r) => setTimeout(r, ms));
    }
    async copy(link: string, allPayes: boolean, onlyLink: boolean) {

        if (onlyLink) {
            this._clipboardService.copy(link);
        } else {
            let text = ''
            if (allPayes) {
                text = `Hej!\n\nFölj länken och checka in!\n\n${link}\n\nDu är inbokad hos The Melody Club\n\nBokare: ${this.activeTableBooking.firstName} ${this.activeTableBooking.lastName}\nDatum: ${this.activeTableBooking.dateTimePretty}\nKlockan: ${this.activeTableBooking.startTimePretty}\nVid frågor:\nBokning: 031-7933000\nE-Mail: info@themelodyclub.se`
            } else {
                text = `Hej!\n\nFölj länken, betala och checka in!\n\n${link}\n\nDu är inbokad hos The Melody Club\n\nBokare: ${this.activeTableBooking.firstName} ${this.activeTableBooking.lastName}\nDatum: ${this.activeTableBooking.dateTimePretty}\nKlockan: ${this.activeTableBooking.startTimePretty} \nVid frågor:\nBokning: 031-7933000\nE-Mail: info@themelodyclub.se`
            }

            if (navigator && navigator.clipboard) {
                navigator.clipboard.writeText(text)
                    .then(async () => {
                        let toast = await this.toastController.create({
                            message: 'Informationen har kopierats',
                            duration: 2000,
                            color: 'primary',

                        });

                        toast.present();
                    })
                    .catch((err) => {
                        alert("Kunde inte kopiera informationen på denna enhet. Markera och kopierat texten istället.")
                    });
            } else {
                this._clipboardService.copy(text)
            }
        }
    }

    peopleLeft(activeTableBooking) {
        if (!activeTableBooking) return 0;
        if (!activeTableBooking.guests)
            return activeTableBooking.peopleCount;
        return (activeTableBooking.peopleCount) - Object.keys(activeTableBooking.guests).length
    }
}

