import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfigService } from "../services/config.service";

@Component({
    templateUrl: "./closed.component.html",
    selector: "app-closed",
    styleUrls: ['./closed.component.scss']
})
export class ClosedComponent {
  
    routeName = ''
    initialLoading = true;

    constructor(private activatedRoute: ActivatedRoute, private configService: ConfigService) {
        this.activatedRoute.queryParams.subscribe(x => {
            this.init(x);
          });
    }

    async init(x){
        this.routeName = x.r ? x.r : "";
        await this.setClientTheme(this.routeName);
        this.configService.init(this.routeName)
    }

    async setClientTheme(routeName) {
        await this.configService.setClientTheme(routeName);
        this.initialLoading = false;
    }
}