import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
declare var FontFace;
@Injectable({
    providedIn: "root"
})
export class ConfigService {

    constructor(private afDatabase: AngularFireDatabase) {

    }
    async init(route: string) {
        console.log("Route");
        console.log(route);
    }
    async setClientTheme(routeName: string) {
        let theme = (
            await this.afDatabase.database
                .ref(`Clients/${routeName}/theme/booking`)
                .once("value")
        ).val();
        document.documentElement.style.setProperty(
            "--ion-color-primary",
            theme.primary.hex
        );
        document.documentElement.style.setProperty(
            "--ion-color-primary-shade",
            theme.primary.shade
        );
        document.documentElement.style.setProperty(
            "--ion-color-primary-tint",
            theme.primary.tint
        );
        document.documentElement.style.setProperty(
            "--ion-color-secondary",
            theme.secondary.hex
        );
        document.documentElement.style.setProperty(
            "--ion-color-secondary-shade",
            theme.secondary.shade
        );
        document.documentElement.style.setProperty(
            "--ion-color-secondary-tint",
            theme.secondary.tint
        );
        if (theme.bg && theme.bg.hex) {
            document.documentElement.style.setProperty(
                "--booking-background",
                theme.bg.hex
            );
        }

        if (theme.bgcontrast && theme.bgcontrast.hex) {
            document.documentElement.style.setProperty(
                "--ion-color-main-background-contrast",
                theme.bgcontrast.hex
            );
            document.documentElement.style.setProperty(
                "--booking-background-contrast",
                theme.bgcontrast.hex
            );
        }
        document.documentElement.style.setProperty("--overflow", "hidden");
        let font = new FontFace(theme.font.name, `url(${theme.font.url})`, {
            style: "normal",
            weight: 400
        });
        let face = await font.load()
        document["fonts"].add(face);
        document.documentElement.style.setProperty(
            "--ion-font-family",
            `${theme.font.name}`
        );

        return true;
    }
}