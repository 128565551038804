import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NavController, LoadingController } from "@ionic/angular";
import * as moment from "moment";
import { environment } from "src/environments/environment";




@Component({
    selector: "book-display",
    templateUrl: "bookingDisplay.component.html",
    styleUrls: ["bookingDisplay.component.scss"]
})
export class BookingDisplayComponent {
    id: string;
    routeName: string;
    activeTableBooking: any;
    loadingBooking = true;
    showCongratulationsMessage = false;
    constructor(private activatedRoute: ActivatedRoute, private http: HttpClient, private router: Router, private navCtrl: NavController, private loadingController: LoadingController) {
        moment.locale("sv-se");
        this.activatedRoute.queryParams.subscribe(x => {
            this.showCongratulationsMessage = x.congrats;
        })
        this.activatedRoute.params.subscribe(x => {
            this.id = x.id;
            this.routeName = x.route;
            let url = `${environment.pubQueueApiUrl}/getbooking?r=${this.routeName}&b=${this.id}`;
            this.http.post<any>(url, null).subscribe((x) => {
                this.activeTableBooking = x;
                let startTimeMoment = moment(this.activeTableBooking.startTimeUTC * 1000).local();
                let endTimeMoment = moment(this.activeTableBooking.endTimeUTC * 1000).local();
                this.activeTableBooking.dateTimePretty = startTimeMoment.format('dddd, D MMMM YYYY').toUpperCase();
                this.activeTableBooking.startTimePretty = startTimeMoment.format("kk:mm");
                if (parseInt(this.activeTableBooking.startTimePretty) === 24)
                    this.activeTableBooking.startTimePretty = this.activeTableBooking.startTimePretty.replace("24", "00");
                this.activeTableBooking.endTimePretty = endTimeMoment.format("kk:mm");
                if (parseInt(this.activeTableBooking.endTimePretty) === 24)
                    this.activeTableBooking.endTimePretty = this.activeTableBooking.endTimePretty.replace("24", "00");
                this.loadingBooking = false;
            }, (error) => {
                alert(JSON.stringify(error));
            });

        });
    }
    async cancelBooking() {
        let loading = await this.loadingController.create({
            spinner: "crescent",
            message: "Avbokar",
            duration: 6000
        });
        loading.present();
        let data = {
            unitRouteName: this.routeName,
            bookingId: this.id
        };
        let url = `${environment.primeApiUrl}/canceltimeslot`;
        this.http.post<any>(url, data).subscribe(x => {
            this.navCtrl.navigateBack("/");
            loading.dismiss();
        });
    }
}