import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Booking } from "./bookingState.service";


@Injectable({
    providedIn: "root"
})
export class PaymentUtils {

    private retryingEnabled: boolean = true;

    constructor(
    ) {

    }

    async initiatePaymentWithRetry(body: any, http: HttpClient) {
        this.retryingEnabled = true;
        let resp: PaymentV2Response = undefined;
        let finished = false;
        let retry = 0;
        let error = null;
        let url = `${environment.pubQueueApiUrl}/payments/v1/idempotency/initiate`;

        console.log("Initiating...")
        while (!finished && this.retryingEnabled) {
            try {
                resp = await http.post<PaymentV2Response>(url, body).toPromise();

                error = null;
                if (resp.success || resp.error) {
                    console.log("Initiate done.")
                    finished = true;
                }
            } catch (err) {
                error = err;
                console.log(err)
            }

            retry++;
            if (retry > 120 || finished) { // Wait max for 2 minutes
                finished = true;
            } else {
                console.log("Retry in 1sec (" + retry + "/120)...")
                await this.delay(1000);
            }
        }
        if (error) {
            resp = new PaymentV2Response;
            resp.error = {
                showResponseText: false,
                languageCode: 'SWISH_ERROR_CONNECTION',
                responseText: error.message,
                resetOrder: true
            }
        }
        // Handling if the retry count exceeds 120 retries
        else if (retry > 120) {
            resp = new PaymentV2Response;
            resp.error = {
                showResponseText: false,
                languageCode: 'SWISH_CANCELLED',
                responseText: '',
            }
        }

        return resp;
    }

    async chargePaymentWithRetry(body: any, http: HttpClient) {
        this.retryingEnabled = true;
        let resp: PaymentV2Response = undefined;
        let finished = false;
        let retry = 0;
        let error = null;
        let url = `${environment.pubQueueApiUrl}/payments/v1/idempotency/charge`;

        console.log("Charging...")
        while (!finished && this.retryingEnabled) {
            try {
                resp = await http.post<PaymentV2Response>(url, body).toPromise();

                error = null;
                if (resp.success || resp.error) {
                    console.log("Charge done.")
                    finished = true;
                }
            } catch (err) {
                error = err;
                console.log(err)
            }

            retry++;
            if (retry > 120 || finished) { // Wait max for 2 minutes
                finished = true;
            } else {
                console.log("Retry in 1sec (" + retry + "/120)...")
                await this.delay(1000);
            }
        }
        if (error) {
            resp = new PaymentV2Response;
            resp.error = {
                showResponseText: false,
                languageCode: 'SWISH_ERROR_CONNECTION',
                responseText: error.message,
                resetOrder: true
            }
        }
        // Handling if the retry count exceeds 120 retries
        else if (retry > 120) {
            resp = new PaymentV2Response;
            resp.error = {
                showResponseText: false,
                languageCode: 'SWISH_CANCELLED',
                responseText: '',
            }
        }

        return resp;
    }

    async verifyPaymentWithRetry(idempotencyKey: string, routeName: string, http: HttpClient, retryMaxCount?: number): Promise<PaymentV2Response> {
        this.retryingEnabled = true;
        let resp: PaymentV2Response = undefined;
        let finished = false;
        let _retryMaxCount = retryMaxCount
        let retry = 0;
        let error = null;
        const body = {
            idempotencyKey: idempotencyKey,
            routeName: routeName,
        };
        let url = `${environment.pubQueueApiUrl}/payments/v1/idempotency/verify/v2`;
        console.log("Verifying...")
        while (!finished && this.retryingEnabled) {
            try {

                resp = await http.post<PaymentV2Response>(url, body).toPromise();

                error = null;
                if (resp.success || resp.error) {
                    console.log("Verify done.")
                    finished = true;
                }
            } catch (e) {
                error = e;
            }
            retry++;
            if (retry > _retryMaxCount || finished) { // Wait max for 45 sec
                finished = true;
            } else {
                console.log("Retry in 1sec (" + retry + "/45)...")
                await this.delay(1000);
            }
        }
        if (error) {
            resp = new PaymentV2Response;
            resp.error = {
                showResponseText: false,
                languageCode: 'SWISH_ERROR_CONNECTION',
                responseText: error.message,
                resetOrder: true
            }
        }
        // Handling if the retry count exceeds 120 retries
        else if (retry > 120) {
            resp = new PaymentV2Response;
            resp.error = {
                showResponseText: false,
                languageCode: 'SWISH_CANCELLED',
                responseText: '',
            }
        }
        return resp;
    }

    cancelRetrying() {
        this.retryingEnabled = false;
    }

    private delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
export class PaymentV2Response {
    idempotencyKey: string;
    success: boolean;
    approvedOrder?: any;
    providerData?: any;
    error?: {
        showResponseText: boolean,
        languageCode: string,
        responseText: string,
        resetOrder?: boolean;
    };
    missing?: any;
    updatedCart?: any;
}
export class Order {
    id?: string;
    version: string;
    appType: string;
    userId: string;
    orderCode: string;
    booking?: Booking;
    order: { food?: any[], drinks?: any[] };
    date: number;
    dateISO: string;
    deliveryDate: number;
    tableNumber: number;
    client: ClientInfo;
    momsRegNr: string;
    deviceToken: string;
    deviceToken_v2: { token: string; projectId: string };
    totalItems: number;
    totalCost: number;
    totalFoodCost: number;
    totalDrinksCost: number;
    totalFoodItems: number;
    totalDrinkItems: number;
    estimatedFinishTime: Date;
    customerComment: string;
    coupon: any;
    bonusVoucher: { amountUsedInPurchase: number, voucher: any; };
    payment?: any;
    deliveryComment?: string;
    deliveryRadius?: any;
    deliveryAddress?: string;
    deliveryLat?: number;
    deliveryLong?: number;
    deliveryFee?: number;
    phoneNumber?: string;
    userName?: string;
    email?: string;
    tabKey?: string;
    orderType?: string;
    tipAmount?: number;
}
export class ClientInfo {
    name: string;
    routeName: string;
    address: string;
    phoneNumber: string;
    location: Location;
    imageUrl?: string;
    distanceInKm?: number;
}