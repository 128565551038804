import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { Guid } from 'guid-typescript';
import { CardPaymentProvider } from "./cardPaymentProvider";
import { ConfigService } from "./config.service";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class BookingStateService {
    booking: Booking = new Booking();
    idempotencyKey: string;
    orderId: string;
    preOrderItems: any[];
    bookingItem;
    currentRoute;
    constructor(private afDatabase: AngularFireDatabase, private cardPaymentProvider: CardPaymentProvider, private configService: ConfigService, private http: HttpClient) {
        this.booking.comment = "testar";
        this.booking.email = "hwallden@gmail.com";
        this.booking.firstName = "Henrik";
        this.booking.lastName = "Walldén";
        this.booking.phoneNumber = "";
        this.booking.phonePrefix = "";
        this.booking.seats = 5;
        this.booking.unitRouteName = "";
        this.booking.timeslotUTC = moment("2021-11-14 19:00").unix();
    }
    async init(route: string) {
        this.currentRoute = route;
        console.log(route);
        this.getItems(route);
        this.getBookingItem(route);
        this.configService.setClientTheme(route);
    }

    async getItems(route: string) {
        if (this.preOrderItems)
            return this.preOrderItems;
        const items = await this.http.get<any[]>(`${environment.dashboardApiUrl}/v1/units/products/booking`, { params: { unit: route } }).toPromise();
        this.preOrderItems = items;
        return this.preOrderItems;
    }
    async getBookingItem(route: string) {
        if (this.bookingItem)
            return this.bookingItem;

        let item = (await this.afDatabase.database.ref(`adminApp/clients/${route}/settings/tables/bookingItem`).once("value")).val();
        this.bookingItem = item;
        return this.bookingItem;
    }
    async getBooking(route, bookingId) {
        return (await this.afDatabase.database.ref(`tablebookings/${route}/${bookingId}`).once("value")).val();
    }
    async createChargeSession(options: any) {
        let clientContextData = {
            user: {
                id: this.makeid(8),
                isAnonymous: true,
                firstName: this.booking.firstName,
                lastName: this.booking.lastName,
                deviceToken: null,
                email: this.booking.email,
                phoneNumber: `${this.booking.phonePrefix}${this.booking.phoneNumber}`,
                preferredLocale: "se",
                origin: this.currentRoute,
            },
            origin: window.location.origin,
            locale: "se",
            appVersion: `web_booking@${environment.version}`,
            appProtocol: ``
        }

        let _options: any = {
            paymentSource: { action: 'newcard', recurringReference: null, nickname: "TBD", type: "reepay" },
            order: options.order,
            clientContextData: clientContextData,
        }

        const idempotencyKey = this.idempotencyKey;
        const orderId = this.orderId;

        // return this.http.post<any>(url, body).toPromise();
        return await this.cardPaymentProvider.initiate(_options, idempotencyKey, orderId)
    }

    async createFreechargeSession(options: any) {
        let clientContextData = {
            user: {
                id: this.makeid(8),
                isAnonymous: true,
                firstName: this.booking.firstName,
                lastName: this.booking.lastName,
                deviceToken: null,
                email: this.booking.email,
                phoneNumber: `${this.booking.phonePrefix}${this.booking.phoneNumber}`,
                preferredLocale: "se",
                origin: this.currentRoute,
            },
            origin: window.location.origin,
            locale: "se",
            appVersion: `web_booking@${environment.version}`,
            appProtocol: ``
        }

        let _options: any = {
            paymentSource: { recurringReference: "free", type: 'reepay', nickname: "Free" },//{ action: 'newcard', recurringReference: null, nickname: "TBD", type: "reepay" },
            order: options.order,
            clientContextData: clientContextData,
        }

        const idempotencyKey = this.idempotencyKey;
        const orderId = this.orderId;

        // return this.http.post<any>(url, body).toPromise();
        return await this.cardPaymentProvider.initiate(_options, idempotencyKey, orderId)
    }

    createBookingId() {
        // return Guid.create().toString().replace('-', '');
        return this.retrieveId(6);
    }
    createCheckinId() {
        return this.retrieveId(4)
    }
    createGuestId() {
        return this.retrieveId(4);
    }
    private getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    private retrieveId(length) {
        var letters = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "x", "y", "z"]
        var capitalLetters = letters.map(x => x.toUpperCase());
        var numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        var approvedIdChars = letters.concat(capitalLetters).concat(numbers);
        var char = "";
        for (let i = 0; i < length; i += 1) {
            char += approvedIdChars[this.getRandomInt(1, approvedIdChars.length) - 1];
        }
        return char;
    }
    updateIdempotencyKey(): string {
        this.idempotencyKey = Guid.create().toString().replace('-', '');

        return this.idempotencyKey;
    }
    updateOrderId(): string {
        this.orderId = 'or_' + this.makeid(14);

        return this.orderId;
    }
    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }
}

export class Booking {
    id: String
    unitRouteName: string;
    seats: number | string;
    bookerIncluded: boolean = true;
    nameArtist: string;
    firstName: string;
    lastName: string;
    phonePrefix: string;
    phoneNumber: string;
    comment: string;
    email: string;
    guests: any = {};
    checkins: any = {};
    isNew = false;
    timeslotUTC: string | number;
    bookingPriceItem: any;
    preorderedItems?: any[] = [];
    paymentOption: any;
    payForAll: boolean = true;
    allSeatsArePayed: boolean = false;
    startTimeUTC?: number;
    originalBookingIdFromPOS?: string;
    guestsToAdd: Guest[] = [];
}


export class Checkin {
    artists: string[]
    preorderdItems: any[]
    timesUTC: number = moment().utc().unix();
    order?: { id: string }
    receipt?: any
    key?: string

    constructor(artists: string[], preorderdItems: any[], order?: { id: string }) {
        this.artists = artists
        this.preorderdItems = preorderdItems
        if (order) this.order = order;
    }
}

export class Guest {
    fullname: string
    name: string
    email: string
    phone: string
    paid: boolean
    acceptanceOfConditions: boolean
    acceptanceOfNewsletter: boolean
    child: boolean
    parentIndex: number
    key?: string
}