import { Component, Input } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Booking, BookingStateService } from "src/app/services/bookingState.service";
import { Order, PaymentUtils, PaymentV2Response } from "src/app/services/paymentUtils";
import { SwishPaymentProvider } from "src/app/services/swishPaymentProvider";
import { environment } from "src/environments/environment";

@Component({
    selector: "add-card",
    styleUrls: ["swish-dialog.component.scss"],
    templateUrl: "swish-dialog.component.html",
})
export class SwishDialogComponent {
    @Input() order: Order
    @Input() booking: Booking
    @Input() paymentOptions: { user: any, paymentSource: any, order: Order }
    @Input() phone: string
    swishPhoneNumber: string;
    qrCode: string = null;
    payOnThisDevice: boolean = false;
    payOnOtherDevice: boolean = false;
    showOpenApp: boolean = false;
    paymentRequestToken
    verifying = false;
    prepering = false;
    phoneInputShown = false;
    constructor(
        private modalController: ModalController,
        private platform: Platform,
        private swishPaymentProvider: SwishPaymentProvider,
        private bookingStateService: BookingStateService,
        private paymentUtils: PaymentUtils) {
    }
    isPhone() {
        return this.platform.is("mobile") || this.platform.is("mobileweb") || this.platform.is("ios") || this.platform.is("android") || this.platform.is("cordova") || this.platform.is("capacitor")
    }
    async showPhoneInput() {
        this.phoneInputShown = true;
    }
    confirmPhoneNumber() {
        if (this.swishPhoneNumber.startsWith("0")) {
            this.swishPhoneNumber = `+46${this.swishPhoneNumber.substring(1)}`;
        }
        if (this.swishPhoneNumber.startsWith("7")) {
            this.swishPhoneNumber = `+46${this.swishPhoneNumber}`;
        }
        this.payOnThisDeviceSelected();
    }
    cancelPhoneNumber() {
        this.phoneInputShown = false;
    }
    async payOnThisDeviceSelected() {
        this.phoneInputShown = false;
        this.prepering = true;
        this.payOnThisDevice = true;
        this.payOnOtherDevice = false;

        let clientContextData = {
            user: {
                id: this.order.userId,
                isAnonymous: true,
                firstName: this.booking.firstName,
                lastName: this.booking.lastName,
                deviceToken: null,
                email: this.booking.email,
                phoneNumber: `${this.booking.phonePrefix}${this.booking.phoneNumber}`,
                preferredLocale: "se",
                origin: this.bookingStateService.booking.unitRouteName,
            },
            origin: window.location.origin,
            locale: "se",
            appVersion: `web_booking@${environment.version}`,
            appProtocol: ``,
            mobileNumber: `${this.swishPhoneNumber}`
        }

        let _options: any = {
            paymentSource: {
                nickname: "swish",
                recurringReference: null,
                type: "swish",
            },
            order: this.order,
            clientContextData: clientContextData,
        }

        let response = await this.swishPaymentProvider.initiate(_options, this.bookingStateService.idempotencyKey, this.bookingStateService.orderId);
        this.prepering = false;
        // Charge payment if init was a success
        if (response && response.success) {
            this.paymentRequestToken = response.providerData.providerData.paymentRequestToken
            this.showOpenApp = true
        } else {
            this.handleResponse(response);
        }
        this.swishPhoneNumber = "";
    }

    async payOnOtherDeviceSelected() {
        this.prepering = true;
        this.payOnThisDevice = false;
        this.payOnOtherDevice = true;

        let clientContextData = {
            user: {
                id: this.order.userId,
                isAnonymous: true,
                firstName: this.booking.firstName,
                lastName: this.booking.lastName,
                deviceToken: null,
                email: this.booking.email,
                phoneNumber: `${this.booking.phonePrefix}${this.booking.phoneNumber}`,
                preferredLocale: "se",
                origin: this.bookingStateService.booking.unitRouteName,
            },
            origin: window.location.origin,
            locale: "se",
            appVersion: `web_booking@${environment.version}`,
            appProtocol: ``
        }

        let _options: any = {
            paymentSource: {
                nickname: "swish",
                recurringReference: null,
                type: "swish",
            },
            order: this.order,
            clientContextData: clientContextData,
        }

        let response = await this.swishPaymentProvider.initiate(_options, this.bookingStateService.idempotencyKey, this.bookingStateService.orderId);


        // Charge payment if init was a success
        if (response && response.success) {
            this.qrCode = await this.swishPaymentProvider.getQrCode(response.providerData.providerData.paymentRequestToken);
            this.prepering = false;
        } else {
            this.handleResponse(response);
        }
    }
    confirmKeyDown(event) {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            this.confirmPhoneNumber();
        }
    }
    async verify() {
        this.verifying = true;
        let response = await this.swishPaymentProvider.verify(this.bookingStateService.idempotencyKey, this.bookingStateService.booking.unitRouteName);
        this.verifying = false;
        if (response.success) {
            this.modalController.dismiss(response, "success")
        } else if (response.error) {
            this.handleResponse(response);
        }
    }

    openSwish() {
        const ua = navigator.userAgent.toLowerCase();
        const isAndroid = ua.indexOf("android") > -1; // android check
        const isIphone = ua.indexOf("iphone") > -1; // ios check


        let appUrl = `swish://paymentrequest?token=${this.paymentRequestToken}`;

        if (isAndroid || isIphone) {
            setTimeout(function () {
                window.location.href = appUrl;

            }, 25);
        }
    }

    handleResponse(response: PaymentV2Response) {
        if (response.success) {
            this.modalController.dismiss(response, "success")
        } else if (response.error && response.error.responseText) {
            this.clearSelection();
            alert(response.error.responseText)
        } else {
            this.clearSelection();
        }
    }

    clearSelection() {
        this.bookingStateService.updateIdempotencyKey();
        this.payOnOtherDevice = false;
        this.payOnThisDevice = false;
        this.showOpenApp = false;
        this.paymentRequestToken = null;
        this.verifying = false
        this.prepering = false;
    }

    cancel() {
        this.clearSelection();
        this.paymentUtils.cancelRetrying();
        this.modalController.dismiss(null, "cancel")
    }
}