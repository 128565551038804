import { appVersion } from './version';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const database = {
  apiKey: "AIzaSyBmfb5lbJ29AdVQyp0Jk1Tug6pekx_fTwA",
  authDomain: "pq2-staging.firebaseapp.com",
  databaseURL: "https://pq2-staging.firebaseio.com",
  projectId: "pq2-staging",
  storageBucket: "pq2-staging.appspot.com",
  messagingSenderId: "438992683153"
};
export const environment = {
  production: false,
  primeApiUrl: "https://us-central1-joboffice-prime-staging.cloudfunctions.net",
  pubQueueApiUrl: "https://us-central1-pq2-staging.cloudfunctions.net",
  dashboardApiUrl: "https://dashboard2-ue6wk323oq-ew.a.run.app",
  version: appVersion,
  database: database,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
