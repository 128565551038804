import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Order, PaymentUtils, PaymentV2Response } from './paymentUtils';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: "root"
})
export class SwishPaymentProvider {
    appBuild = "";
    sub: Subscription;

    constructor(
        private http: HttpClient,
        private paymentUtils: PaymentUtils
    ) {

    }

    ngOnDestory() {
        if (this.sub) this.sub.unsubscribe();
    }

    async initiate(options: { clientContextData: any, paymentSource: any, order: any }, idempotencyKey: string, orderId: string): Promise<PaymentV2Response> {
        let receiptOnly = false;
        try {
            receiptOnly = true;
        } catch (error) {
            receiptOnly = false;
        }

        //options.order.id = orderId;

        let providerData = {
            UserId: options.clientContextData.user.id,
            //mobileNumber: options.order.phoneNumber
        } as any;

        console.dir(options)

        if(options.clientContextData.mobileNumber){
            providerData.mobileNumber = options.clientContextData.mobileNumber;
        }

        //testPayment needs to be removed (set to false!) when backend is ready
        const body = {
            idempotencyKey: idempotencyKey,
            userPaymentSource: options.paymentSource,
            order: options.order,
            paymentType: options.paymentSource.type,
            appVersion: this.appBuild && this.appBuild !== '' ? this.appBuild : 'N/A',
            routeName: options.order.client.routeName,
            receiptOnly,
            providerData,
            testPayment: false
        };

        console.dir(body);

        // let url = `${this.config.ApiUrl}/payments/v1/idempotency/initiate`;
        // let resp = await this.http.post<PaymentV2Response>(url, body).toPromise();
        // return resp;
        return this.paymentUtils.initiatePaymentWithRetry(body, this.http);
    }

    async charge(idempotencyKey: string, routeName: string): Promise<PaymentV2Response> {

        const body = {
            idempotencyKey: idempotencyKey,
            routeName: routeName,
        };

        let url = `${environment.pubQueueApiUrl}/payments/v1/idempotency/charge`;
        let resp = await this.http.post<PaymentV2Response>(url, body).toPromise();
        return resp;
    }

    private delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async verify(idempotencyKey: string, routeName: string): Promise<PaymentV2Response> {
        
        return this.paymentUtils.verifyPaymentWithRetry(idempotencyKey, routeName, this.http, 0);
    }

    async getQrCode(token): Promise<any> {
        return new Promise<any>(async (resolve) => {

            try {
                const body = {
                    token,
                    format: 'png',
                    size: 300
                };

                let url = `${environment.pubQueueApiUrl}/payments/v1/swish/qr`;
                let image = await this.http.post(url, body, { responseType: 'blob' }).toPromise();

                // with file reader you will transform the file in a data url file;
                var reader = new FileReader();
                reader.readAsDataURL(image);

                reader.onloadend = () => {
                    // just putting the data url to img element
                    resolve(reader.result as string);
                }
            } catch (err) {
                resolve(null);
            }
        })
    }
}
