import { HttpClient } from "@angular/common/http";
import { Component, ViewChild } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { ActivatedRoute } from "@angular/router";
import { IonContent, NavController } from "@ionic/angular";
import * as moment from "moment";
import { BookingStateService } from "src/app/services/bookingState.service";
import { ConfigService } from "src/app/services/config.service";
import { environment } from "src/environments/environment";

@Component({
    templateUrl: "./checkin-completed.component.html",
    styleUrls: ["./checkin-completed.component.scss"],
    selector: "app-checkin-completed"
})
export class CheckinCompletedComponent {
    @ViewChild(IonContent, { static: false }) content: IonContent;
    object = Object;
    id: string;
    routeName: string;
    activeTableBooking: any;
    loadingBooking = true;
    restaurantInfo;
    link: string = '';
    guest: any;
    guestIndex: number = 1;

    error: boolean = false;

    constructor(
        private afDatabase: AngularFireDatabase, 
        private bookingStateService: BookingStateService, 
        private activatedRoute: ActivatedRoute, 
        private http: HttpClient, 
        private configService: ConfigService,
        private navCtrl: NavController) 
    {
        moment.locale("sv-se");
    }

    async ngOnInit() {
        let queryParams = this.activatedRoute.snapshot.queryParams
        // this.showCongratulationsMessage = queryParams.congrats;
        let params = this.activatedRoute.snapshot.params;
        this.id = params.id;
        this.routeName = params.route;
        this.guestIndex = params.guestId;


        this.link = window.location.hostname.startsWith('localhost') ? `http://${window.location.hostname}:8100/booking/${this.routeName}/${this.id}` : `https://${window.location.hostname}/booking/${this.routeName}/${this.id}`

        await this.configService.setClientTheme(this.routeName);
        this.bookingStateService.init(this.routeName);
        let url = `${environment.pubQueueApiUrl}/getbooking?r=${this.routeName}&b=${this.id}`;
        let bookingPromise = this.http.post<any>(url, null).toPromise();
        let restaurantPromise = this.afDatabase.database.ref(`adminApp/clients/${this.routeName}/info/public`).once("value");
        this.activeTableBooking = await bookingPromise;

        if(!this.activeTableBooking){
            this.error = true
            return;
        }

        this.activeTableBooking.id = this.id;
        this.restaurantInfo = (await restaurantPromise).val();
        this.bookingStateService.booking = this.activeTableBooking;
        let startTimeMoment = moment(this.activeTableBooking.startTimeUTC * 1000).local();
        let endTimeMoment = moment(this.activeTableBooking.endTimeUTC * 1000).local();
        this.activeTableBooking.dateTimePretty = startTimeMoment.format('dddd, D MMMM YYYY').toUpperCase();
        this.activeTableBooking.startTimePretty = startTimeMoment.format("HH:mm");
        this.activeTableBooking.endTimePretty = endTimeMoment.format("HH:mm");
        this.loadingBooking = false;

        console.dir(this.activeTableBooking)
    }

    openBookingComplete(){
        this.navCtrl.navigateBack(`booking/${this.activeTableBooking.unitRouteName}/${this.activeTableBooking.id}`)
    }

    openHomePage(){
        window.open('https://www.themelodyclub.com', '_self', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }
}
