import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NavController, LoadingController, AlertController, ModalController, IonContent } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import * as moment from 'moment'
import { AngularFireDatabase } from "@angular/fire/database";
import { BookingStateService } from "../../services/bookingState.service";
import { ConfigService } from "../../services/config.service";

@Component({
    templateUrl: "./acceptance-of-conditions.component.html",
    selector: "app-acceptance-of-conditions"
})
export class AcceptanceOfConditionsComponent {
    @ViewChild(IonContent, { static: false }) content: IonContent;
    object = Object;
    id: string;
    routeName: string;
    activeTableBooking: any;
    loadingBooking = true;
    restaurantInfo;
    link: string = '';
    guest: any;
    guestIndex: number = 1;

    error: boolean = false;
    confirmed: boolean = false;
    confirmedOk: boolean = false;
    acceptanceOfNewsletter: boolean = false;
    acceptedTurms: boolean = false;

    constructor(private afDatabase: AngularFireDatabase, private modalController: ModalController, private bookingStateService: BookingStateService, private activatedRoute: ActivatedRoute, private http: HttpClient, private router: Router, private navCtrl: NavController, private loadingController: LoadingController, private alertController: AlertController, private configService: ConfigService) {
        moment.locale("sv-se");
    }
    async ngOnInit() {
        let queryParams = this.activatedRoute.snapshot.queryParams
        // this.showCongratulationsMessage = queryParams.congrats;
        let params = this.activatedRoute.snapshot.params;
        this.id = params.id;
        this.routeName = params.route;
        this.guestIndex = params.guestId;


        this.link = window.location.hostname.startsWith('localhost') ? `http://${window.location.hostname}:8100/booking/${this.routeName}/${this.id}` : `https://${window.location.hostname}/booking/${this.routeName}/${this.id}`

        await this.configService.setClientTheme(this.routeName);
        this.bookingStateService.init(this.routeName);
        let url = `${environment.pubQueueApiUrl}/getbooking?r=${this.routeName}&b=${this.id}`;
        let bookingPromise = this.http.post<any>(url, null).toPromise();
        let restaurantPromise = this.afDatabase.database.ref(`adminApp/clients/${this.routeName}/info/public`).once("value");
        this.activeTableBooking = await bookingPromise;

        if(!this.activeTableBooking){
            this.error = true
            return;
        }else{

            try{
                this.guest = this.activeTableBooking.guests[Object.keys(this.activeTableBooking.guests)[this.guestIndex]]
                if(this.guest.acceptanceOfConditions) this.acceptedTurms = true;
            }catch(err){
                console.log(err)
                this.error = true;
            }

            if(!this.guest){
                this.error = true;
            }
        }

        this.activeTableBooking.id = this.id;
        this.restaurantInfo = (await restaurantPromise).val();
        this.bookingStateService.booking = this.activeTableBooking;
        let startTimeMoment = moment(this.activeTableBooking.startTimeUTC * 1000).local();
        let endTimeMoment = moment(this.activeTableBooking.endTimeUTC * 1000).local();
        this.activeTableBooking.dateTimePretty = startTimeMoment.format('dddd, D MMMM YYYY').toUpperCase();
        this.activeTableBooking.startTimePretty = startTimeMoment.format("HH:mm");
        this.activeTableBooking.endTimePretty = endTimeMoment.format("HH:mm");
        this.loadingBooking = false;

        console.dir(this.activeTableBooking)
    }

    async onConfirm(){
        this.guest.acceptanceOfConditions = true;

        let key = Object.keys(this.activeTableBooking.guests)[this.guestIndex]
        await this.afDatabase.database.ref(`tablebookings/${this.routeName}/${this.id}/guests/${key}/acceptanceOfNewsletter`).set(this.acceptanceOfNewsletter);
        await this.afDatabase.database.ref(`tablebookings/${this.routeName}/${this.id}/guests/${key}/acceptanceOfConditions`).set(this.acceptedTurms);

        this.confirmed = true;
        this.confirmedOk = true;
    }
  
}
