import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BookSlotComponent } from './book-slot/book-slot.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BookCompletedComponent } from './book-completed/book-completed.component';
import { PaymentPageComponent } from './payment/payment.page';
import { ClosedComponent } from './closed/closed.component';
import { CheckinCompletedComponent } from './book-completed/checkin-completed/checkin-completed.component'
import { ReceiptComponent } from './book-completed/receipt-completed/receipt.component';
import { AcceptanceOfConditionsComponent } from './book-completed/acceptance-of-conditions/acceptance-of-conditions.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  {
    path: 'closed', component: ClosedComponent
  },
  {
    path: 'book', component: BookSlotComponent
  },
  {
    path: 'pay/:routeName', component: PaymentPageComponent
  },
  {
    path: 'pay/:routeName/:bookingId', component: PaymentPageComponent
  },
  {
    path: 'checkin/completed/:route/:id', 
    component: CheckinCompletedComponent
  },
  {
    path: 'booking/:route/:id', 
    component: BookCompletedComponent
  },
  {
    path: 'acceptance/:route/:id/:guestId',
    component: AcceptanceOfConditionsComponent
  },
  {
    path: 'receipt/:route/:id',
    component: ReceiptComponent
  }
];

@NgModule({
  declarations: [BookSlotComponent],
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    IonicModule,
    FormsModule,
    CommonModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
