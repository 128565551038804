import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AlertController, IonContent, LoadingController, ModalController } from "@ionic/angular";

import { AddCardComponent } from "src/app/payment/add-card/addCard.component";
import { BookingStateService, Checkin, Guest } from "src/app/services/bookingState.service";
import { CardPaymentProvider } from "src/app/services/cardPaymentProvider";
import { Order } from "src/app/services/paymentUtils";
import { environment } from "src/environments/environment";

import confetti from 'canvas-confetti';
import { SwishDialogComponent } from "src/app/payment/swish-dialog/swish-dialog.component";


@Component({
    selector: "app-add-guest",
    templateUrl: './add-guest.component.html',
    styleUrls: ['./add-guest.component.scss'],
})
export class AddGuestComponent {
    @Output() onGuestsRegistered = new EventEmitter<{ guests: any[] }>();
    @Input() guestLeftToBeCommited: number = 6;
    @Input() routeName: string;
    @Input() id: number
    @Input() content: IonContent

    acceptedTerms: boolean = false;
    acceptedNewsletter: boolean = false;
    selectedNrOfGuestsNumber;
    selectedNrOfChildGuestsNumber;
    nrOfGuests = [1, 2, 3, 4, 5, 6];
    nrOfChildGuests = [1, 2, 3, 4, 5, 6];
    selectedNrOfGuests: Guest[] = [];
    bookingItem;
    preorderItems = [];
    preorderItemsToAdd = [];
    name;
    email;
    fullname

    couponCode: string;
    closingTime: string;
    couponCodeIconStyle = {
        icon: 'barcode',
        color: 'grey'
    };
    couponCodeEnabled = true;
    validCoupon = null;

    paymentOptions = [
        { name: "Kort", type: "card", imgUrl: "./assets/cards.png", isSelected: false },
        { name: "Swish", type: "swish", imgUrl: "./assets/swish.png", isSelected: false }
    ];
    validation_messages = {
        'firstName': [
            { type: 'required', message: 'Fyll i ditt namn' },
            { type: 'minlength', message: 'Namnet måste vara minst 4 tecken långt' }
        ],
        'lastName': [
            { type: 'required', message: 'Fyll i ditt namn' },
            { type: 'minlength', message: 'Namnet måste vara minst 4 tecken långt' }
        ],
        'email': [
            { type: 'required', message: 'Fyll i din email' },
            { type: 'email', message: 'Fyll i en giltig emailadress' }
        ]
    }
    guestForm: FormGroup;

    constructor(
        private modalController: ModalController,
        public bookingStateService: BookingStateService,
        private loadingController: LoadingController,
        private cardPaymentProvider: CardPaymentProvider,
        private afDatabase: AngularFireDatabase,
        private http: HttpClient,
        private alertController: AlertController
    ) {

    }

    async resetComponent() {
        this.acceptedTerms = false;
        this.acceptedNewsletter = false;
        this.selectedNrOfGuestsNumber = null;
        this.selectedNrOfChildGuestsNumber = null;
        this.nrOfGuests = [1, 2, 3, 4, 5, 6];
        this.nrOfChildGuests = [1, 2, 3, 4, 5, 6];
        this.selectedNrOfGuests = [];
        this.bookingItem = null;
        this.preorderItems = [];
        this.name = null;
        this.email = null;

        this.init()

        setTimeout(() => {
            this.content.scrollToTop();
        }, 300)
    }

    async init() {
        this.nrOfGuests = []
        this.nrOfChildGuests = []
        for (let i = 1; i < this.guestLeftToBeCommited + 1; ++i) {
            this.nrOfGuests.push(i);
        }

        for (let i = 1; i < this.guestLeftToBeCommited; ++i) {
            this.nrOfChildGuests.push(i);
        }
        this.bookingItem = await this.bookingStateService.getBookingItem(this.bookingStateService.currentRoute);

        if (!this.bookingStateService.booking.allSeatsArePayed)
            this.bookingItem.NrOfItems = this.selectedNrOfGuests.length;
        else
            this.bookingItem.NrOfItems = 0

        this.bookingStateService.booking.bookingPriceItem = this.bookingItem;
        this.preorderItems = await this.bookingStateService.getItems(this.bookingStateService.currentRoute);
        if (!this.preorderItems)
            this.preorderItems = [];
        this.guestForm = new FormGroup({
            firstName: new FormControl('', [Validators.required, Validators.minLength(4)]),
            lastName: new FormControl('', [Validators.required, Validators.minLength(4)]),
            email: new FormControl('', [Validators.email, Validators.required])
        });
        if (!this.bookingStateService.booking.preorderedItems)
            this.bookingStateService.booking.preorderedItems = [];
    }

    getReceiptAddress(guests: Guest[]) {
        if (guests && guests.length > 0 && guests[0].email !== '') {
            return `Ditt kvitto kommer skickas via mail till ${guests[0].email}`
        } else return 'Ditt kvitto kommer skickas via mail'
    }

    async ngOnInit() {

        this.resetComponent();
        this.selectPaymentOption(this.paymentOptions[0]);
    }

    allGuestOk() {
        for (let g of this.selectedNrOfGuests) {

            if (!this.validateEmail(g.email)) {
                return false;
            }
            if (!this.validatePhone(g.phone)) {
                return false;
            }

            // fix phone
            if (g.phone && g.phone !== '' && !g.phone.startsWith('+')) {
                if (g.phone.startsWith('0')) {
                    g.phone = g.phone.substring(1, g.phone.length)
                    g.phone = g.phone.padStart(g.phone.length + 3, '+46')
                }
            }

            if (g.fullname === '' && g.child) return false
            if ((g.fullname === '') && !g.child) return false;
        }

        if (!this.selectedNrOfGuests || this.selectedNrOfGuests.length === 0)
            return false

        return true
    }

    canPay() {
        // All info ok for all guests
        if (!this.allGuestOk()) return false

        if (this.selectedNrOfGuestsNumber === 1) {
            if (!this.acceptedTerms)
                return false;
        }

        // Check if anything needs to be payed
        if (this.onlyCommitOk(this.bookingStateService.booking.preorderedItems))
            return true;

        // Is a payment method selected
        let hit = false;
        for (let p of this.paymentOptions) {
            if (p.isSelected)
                hit = true;
        }

        if (!hit) return false;

        return true
    }
    selectItem(item, operation: "add" | "remove") {
        let existingItem = this.preorderItemsToAdd.find(x => x.key === item.key);
        if (operation === "add") {
            if (existingItem)
                existingItem.NrOfItems += 1;
            else
                this.preorderItemsToAdd.push({ NrOfItems: 1, ...item });
        }
        if (operation === "remove") {
            if (existingItem) {
                if (existingItem.NrOfItems === 1) {
                    this.preorderItemsToAdd.splice(this.bookingStateService.booking.preorderedItems.findIndex(x => x.key === item.key), 1);
                }
                else {
                    existingItem.NrOfItems -= 1;
                }
            }

        }
    }
    getButtonText(preOrderItems) {
        if (this.onlyCommitOk(preOrderItems))
            return 'Checka In'

        if (!this.validCoupon) {
            return `Betala ${this.getTotalPrice() / 100} kr`;
        } else {
            return `Betala ${((this.getTotalPrice() - this.getDiscountAmount()) / 100)} kr`;
        }
    }

    onlyCommitOk(preOrderItems) {

        let anyPreSelectedItems = false;

        if (!preOrderItems) return false

        for (let p of preOrderItems) {
            if (p.NrOfItems && p.NrOfItems > 0) {
                anyPreSelectedItems = true;
                break;
            }
        }

        if (this.bookingItem && this.bookingItem.NrOfItems === 0 && !anyPreSelectedItems)
            return true;

        return false
    }

    cancel() {
        this.modalController.dismiss(null, "cancel");
    }
    async register() {
        this.onGuestsRegistered.emit(
            {
                guests: this.selectedNrOfGuests
            }
        );

        await this.resetComponent();
    }

    selectPaymentOption(item) {
        this.paymentOptions.forEach(x => {
            x.isSelected = false;
        });
        item.isSelected = true;
        this.bookingStateService.booking.paymentOption = item;

        this.content.scrollToBottom();
    }

    paymentOptionSelected() {
        return this.bookingStateService.booking.paymentOption;
    }

    selectGuests(guests) {
        this.selectedNrOfGuestsNumber = guests;
        this.selectedNrOfChildGuestsNumber = null
        this.selectedNrOfGuests = [];

        // Remove selection of childes
        this.selectedNrOfChildGuestsNumber = null

        // Change number of chileds that can be added
        if (this.canAddChildes()) {
            this.nrOfChildGuests = []
            for (let i = 1; i < guests + 1; ++i) {
                this.nrOfChildGuests.push(i);
            }
        }

        for (let index = 0; index < guests; index++) {
            this.selectedNrOfGuests.push({ fullname: "", name: "", email: "", phone: "", paid: this.bookingStateService.booking.allSeatsArePayed ? this.bookingStateService.booking.allSeatsArePayed : false, child: false, acceptanceOfConditions: false, acceptanceOfNewsletter: false, parentIndex: 0 });
        }
        if (!this.bookingStateService.booking.allSeatsArePayed) {
            this.bookingItem.NrOfItems = this.selectedNrOfGuests.length;
        } else {
            this.bookingItem.NrOfItem = 0;
        }

        this.content.scrollToPoint(0, 420, 300);
    }

    scrollIntoViewTo(elementId) {
        let y = document.getElementById(elementId).offsetTop;
        let element = document.getElementById(elementId) as Element

        element.scrollIntoView();

    }
    getPreorderItemQuantity(item) {
        let existingItem = this.preorderItemsToAdd.find(x => x.key === item.key);
        return existingItem ? existingItem.NrOfItems : 0;
    }
    selectChildGuests(guests) {
        this.selectedNrOfChildGuestsNumber = guests;
        this.selectedNrOfGuests = [];

        for (let index = 0; index < this.selectedNrOfGuestsNumber; index++) {
            this.selectedNrOfGuests.push({ fullname: "", name: "", email: "", phone: "", paid: this.bookingStateService.booking.allSeatsArePayed ? this.bookingStateService.booking.allSeatsArePayed : false, child: false, acceptanceOfConditions: false, acceptanceOfNewsletter: false, parentIndex: 0 });
        }

        for (let index = 0; index < guests; index++) {
            this.selectedNrOfGuests.push({
                fullname: "",
                name: "",
                email: "",
                phone: "",
                paid: this.bookingStateService.booking.allSeatsArePayed ? this.bookingStateService.booking.allSeatsArePayed : false,
                child: true,
                acceptanceOfConditions: false,
                acceptanceOfNewsletter: false,
                parentIndex: 0
            });
        }
        if (!this.bookingStateService.booking.allSeatsArePayed) {
            this.bookingItem.NrOfItems = this.selectedNrOfGuests.length;
        } else {
            this.bookingItem.NrOfItem = 0;
        }
    }

    getNrOfPossibleParents() {
        let endIndex = this.nrOfGuests.indexOf(this.selectedNrOfGuestsNumber)

        if (endIndex === 0) return [this.nrOfGuests[0]];
        return this.nrOfGuests.slice(0, endIndex);
    }

    selectedParentForChild(child, parentIndex) {
        child.parentIndex = parentIndex;
    }

    canAddChildes() {

        if (this.selectedNrOfGuestsNumber === this.nrOfGuests[this.nrOfGuests.length - 1]) return false;

        return true
    }

    getTotalPrice() {
        if (!this.bookingStateService.booking || !this.bookingStateService.booking.bookingPriceItem)
            return 0;
        let preorderedItemsPrice = 0;
        if (this.preorderItemsToAdd) {
            this.preorderItemsToAdd.forEach(x => {
                preorderedItemsPrice += (x.Cost*x.NrOfItems);
            });
        }
        let bookingPrice = this.bookingStateService.booking.bookingPriceItem.NrOfItems * this.bookingStateService.booking.bookingPriceItem.Cost;
        return preorderedItemsPrice + bookingPrice;
    }
    getDiscountAmount() {
        if (!this.bookingStateService.booking || !this.bookingStateService.booking.bookingPriceItem)
            return 0;
        let bookingPrice = this.bookingStateService.booking.bookingPriceItem.NrOfItems * this.bookingStateService.booking.bookingPriceItem.Cost;
        return (bookingPrice * (this.validCoupon ? this.validCoupon.discountPercent / 100 : 0));
    }
    async sendInformationOfAcceptence(loader) {
        try {

            const body = {
                routeName: this.bookingStateService.booking.unitRouteName,
                bookingId: this.id
            }

            await this.http.post(environment.pubQueueApiUrl + "/notifyBooking", body).toPromise()
            loader.dismiss();
        } catch (error) {
            loader.dismiss();
            this.showOkAlert('Lyckades inte skicka information via e-post/SMS.');
        }
    }

    private async showOkAlert(text: string) {
        const alert = await this.alertController.create({
            header: text,
            buttons: [
                {
                    text: 'Ok',
                    handler: () => {
                    }
                }
            ]
        });
        alert.present();
    }

    async createSession() {

        let checkin: any;
        let loading = await this.loadingController.create({
            spinner: "crescent"
        });
        loading.present();

        // Update guest objects
        this.selectedNrOfGuests.forEach(g => {
            g.paid = false;

            // Set artist name to fullname if no name
            if (g.name === '') {
                g.name = g.fullname;
            }

            // Add parent info to child
            if (g.child) {
                g.email = this.selectedNrOfGuests[g.parentIndex].email
                g.phone = this.selectedNrOfGuests[g.parentIndex].phone
            }

            // Set the guest to accepted the terms if no phone or email is provided
            if (g.email === '' && g.phone === '')
                g.acceptanceOfConditions = true;

            // If only one to checkin set acceptanceOfConditions
            if (this.selectedNrOfGuestsNumber === 1)
                g.acceptanceOfConditions = true;
        }
        );


        // Check if user only should commit
        if (this.onlyCommitOk(this.bookingStateService.booking.preorderedItems)) {
            this.selectedNrOfGuests.forEach(g => {
                g.paid = true;
            })

            // Update guest on booking
            try {

                for (let index = 0; index < this.selectedNrOfGuests.length; index++) {
                    const refUrl = `tablebookings/${this.routeName}/${this.id}/guests`;
                    const post = await this.afDatabase.database.ref(refUrl).push();

                    this.selectedNrOfGuests[index].key = post.key

                    await this.afDatabase.database.ref(`tablebookings/${this.routeName}/${this.id}/guests/${post.key}`).set(this.selectedNrOfGuests[index]);
                }
            } catch (err) {
                console.log(err)
            }

            let artists: string[] = [];

            this.selectedNrOfGuests.forEach(
                (s) => artists.push(s.name !== '' ? s.name : s.fullname)
            )

            checkin = new Checkin(
                artists,
                this.bookingStateService.booking.preorderedItems,
                null
            )
            checkin.key = this.bookingStateService.createCheckinId();
            this.afDatabase.database.ref(`tablebookings/${this.routeName}/${this.id}/checkins/${checkin.key}`).set(checkin);

            await this.sendInformationOfAcceptence(loading)
            await this.register();
            return;
        }
        this.bookingStateService.booking.guestsToAdd = this.selectedNrOfGuests.map(x => {
            return {
                acceptanceOfConditions: this.selectedNrOfGuests.length > 1 ? false : this.acceptedTerms,
                acceptanceOfNewsletter: this.selectedNrOfGuests.length > 1 ? false : this.acceptedNewsletter,
                child: false,
                email: x.email ? x.email : '',
                fullname: x.fullname ? x.fullname : '',
                name: x.name ? x.name : '',
                paid: false,
                parentIndex: -1,
                phone: x.phone ? x.phone : '',
                key: null
            }
        });
        let order = this.createOrder();

        try {
            // Init payment
            let response = null;

            // FREECHARGE
            if (this.getTotalPrice() - this.getDiscountAmount() <= 0) {
                response = await this.bookingStateService.createFreechargeSession({
                    order
                });
                loading.dismiss();

                if (response.error || !response.providerData || !response.providerData.freecharge) {
                    if (response.error && response.error.responseText)
                        this.showOkAlert(response.error.responseText);

                    this.bookingStateService.updateIdempotencyKey();
                    return
                }

                // SWISH
            } else if (this.bookingStateService.booking.paymentOption.type === "swish") {
                loading.dismiss();
                let swishPay = await this.modalController.create({
                    component: SwishDialogComponent,
                    componentProps: { order, booking: this.bookingStateService.booking, phone: this.selectedNrOfGuests[0] && this.selectedNrOfGuests[0].phone },
                    backdropDismiss: false
                });
                swishPay.present();
                let result = await swishPay.onDidDismiss();
                if (!result.data || result.role != "success") return
                console.dir(result)
                response = result.data;
                // CARD
            } else {
                response = await this.bookingStateService.createChargeSession({
                    order
                });
                loading.dismiss();

                if (response.error || !response.providerData || !response.providerData.id) {
                    if (response.error && response.error.responseText)
                        this.showOkAlert(response.error.responseText);

                    this.bookingStateService.updateIdempotencyKey();
                    return
                }

                var sessionId = response.providerData.id;
                let cardPay = await this.modalController.create({
                    component: AddCardComponent,
                    componentProps: { sessionId },
                    backdropDismiss: false
                });
                cardPay.present();
                let result = await cardPay.onDidDismiss();
                if (!result.data) {
                    // User closed dialog
                    this.bookingStateService.updateIdempotencyKey();
                    return
                }
            }

            loading = await this.loadingController.create({
                spinner: "crescent",
                message: "Bekräftar..."
            });
            loading.present();
            let pollingResult = await this.cardPaymentProvider.verify(this.bookingStateService.idempotencyKey, this.bookingStateService.currentRoute);
            if (pollingResult.success) {

                // Update guest on booking
                // try {

                //     for (let index = 0; index < this.selectedNrOfGuests.length; index++) {
                //         const refUrl = `tablebookings/${this.routeName}/${this.id}/guests`;
                //         const post = await this.afDatabase.database.ref(refUrl).push();

                //         this.selectedNrOfGuests[index].key = post.key

                //         await this.afDatabase.database.ref(`tablebookings/${this.routeName}/${this.id}/guests/${post.key}`).set(this.selectedNrOfGuests[index]);
                //     }
                // } catch (err) {
                //     console.log(err)
                // }

                // try {
                //     let artists: string[] = [];

                //     this.selectedNrOfGuests.forEach(
                //         (s) => artists.push(s.name !== '' ? s.name : s.fullname)
                //     )

                //     let orderObject = {
                //         id: order.id
                //     }
                //     checkin = new Checkin(
                //         artists,
                //         this.bookingStateService.booking.preorderedItems,
                //         orderObject
                //     )
                //     checkin.key = this.bookingStateService.createCheckinId();
                //     this.afDatabase.database.ref(`tablebookings/${this.routeName}/${this.id}/checkins/${checkin.key}`).set(checkin);
                // } catch (err) {
                //     console.log(err)
                // }

                await this.register();
                loading.dismiss();
            }
            else {
                loading.dismiss();
                if (pollingResult.error && pollingResult.error.responseText) {
                    this.bookingStateService.updateIdempotencyKey();
                    this.showOkAlert(pollingResult.error.responseText);
                }
            }
        } catch (error) {
            console.error(error.message);
            loading.dismiss();
        }
    }

    async changeGuestToNotPaid() {

    }

    createOrder() {
        let orderId = this.bookingStateService.updateOrderId();
        this.bookingStateService.updateIdempotencyKey();
        let order = new Order();
        order.order = { food: [], drinks: [] };
        if (this.bookingStateService.booking.bookingPriceItem.NrOfItems > 0) {
            order.order = { food: [this.bookingStateService.booking.bookingPriceItem], drinks: [] }
        }
        this.preorderItemsToAdd && this.preorderItemsToAdd.forEach(x => {
            order.order.drinks.push(x);
        })
        order.client = { routeName: this.bookingStateService.currentRoute, address: "", name: "The Melody Club", phoneNumber: "", location: null };
        order.id = orderId;
        order.totalCost = this.getTotalPrice();
        order.booking = { ...this.bookingStateService.booking };
        delete order.booking.checkins;
        order.userId = "tgNRpho4tGgMus1yihfjeIDP1Ud2";
        if (this.validCoupon) {
            order.coupon = this.validCoupon;
            order.totalCost -= this.getDiscountAmount();
        }
        order.email = order.booking.guestsToAdd[0].email ? order.booking.guestsToAdd[0].email : '';
        return order;
    }
    wait(ms) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(null);
            }, ms);
        });
    }
    validateEmail(email) {
        if (email === '') return true;
        let result = String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        return result;
    }

    validatePhone(phone) {
        if (phone === '') return true;
        let result = String(phone)
            .toLowerCase()
            .match(
                /^(\+\d{2})\d{7,15}$/
            );
        if (!result) result = String(phone)
            .toLowerCase()
            .match(
                /^\d{8,15}$/
            );
        return result;
    }

    async validateCouponCode() {

        this.couponCode = this.couponCode.toUpperCase();
        const clientToValidateCouponAgainst = this.bookingStateService.booking.unitRouteName;
        const loader = await this.loadingController.create({
            message:
                'Lägger på rabattkod', spinner: 'circular'
        });
        loader.present();

        const body = {
            couponCode: this.couponCode,
            client: clientToValidateCouponAgainst
        }
        this.http.post(environment.pubQueueApiUrl + "/checkcoupon", body).subscribe(data => {
            //this.paymentProvider.validCoupon = data;
            loader.dismiss();
            this.validCoupon = data;
            confetti(
                {
                    particleCount: 65,
                    angle: 120,
                    spread: 55,
                    origin: { x: 1, y: 0.7 }
                }
            );

            setTimeout(() => {
                confetti.reset();
            }, 1500);
            // this.couponCodeIconStyle.icon = 'checkbox';
            // this.couponCodeIconStyle.color = 'green';
        }, error => {
            loader.dismiss();
            this.showOkAlert('Rabattkoden är inte giltig');
        });
    }

    showCouponError() {
        this.validCoupon = null;
        this.couponCodeIconStyle.icon = 'alert';
        this.couponCodeIconStyle.color = 'red';
    }

    resetCouponIconOnEmpty() {
        if (this.validCoupon == null && this.couponCode.length === 0) {
            this.couponCodeIconStyle.icon = 'barcode';
            this.couponCodeIconStyle.color = 'grey';
        }
    }

    async removeCoupon() {

        let alert = await this.alertController.create({
            message: "Ta bort koden?",
            buttons: [{
                text: "Avbryt",
                role: "cancel"
            },
            {
                text: "Ok",
                role: "confirm"
            }
            ]
        });
        alert.present();
        let result = await alert.onDidDismiss();
        if (result.role === "confirm") {
            this.validCoupon = null;
            this.couponCode = "";
            this.couponCodeIconStyle.icon = "barcode";
            this.couponCodeIconStyle.color = "grey";
        }
    }
}
